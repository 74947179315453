<template>
  <!-- Dynamically render the Navbar -->
  <component :is="showNavBar">
    <div class="bg-[#F5F5F5]"><router-view :key="$route.fullPath" /></div>
  </component>
</template>

<script>
import BlankLayout from "./components/BlankView.vue";
import AFTDigital from "./components/AFTDigital.vue";
import Pusher from "pusher-js";
import {
  setAuthToken,
  getAuthToken,
  clearAuthToken,
  setRefreshToken,
} from "./services/authentication";
import { mapActions } from "vuex";
import Cookies from "js-cookie";
import Api from "../src/services/api";

export default {
  name: "App",
  components: { AFTDigital, BlankLayout },

  data() {
    return {
      pusher: null,
      channel: null,
      pusherMessage: null,
      aftMacId: "",
      deviceID: "",
      deviceIDexists: false,

      routeMachineId: "",
      pusherData: null,
      // dynamicMachineId: "4", // temporal
      dynamicMachineId: localStorage.getItem("aftMacId"),
      currentTab: this.$router.history.current.fullPath,
      socket: null,
    };
  },

  computed: {
    shownUUID: function () {
      var newGuid = this.GUID;
      if (this.isUpper === true) {
        newGuid = newGuid.toUpperCase();
      }
      if (this.withHyphen === false) {
        newGuid = newGuid.replace(/[-]/g, "");
      }
      return newGuid;
    },
    // userMachineId() ,
    showNavBar() {
      if (this.$route.meta.layout === "navbar") return "AFTDigital";
      if (this.$route.meta.layout === "blank") return "blank-layout";
      return null;
    },

    currentUserData() {
      return this.$store.state.authentication.currentUser?.data;
    },

    userMachineId() {
      return this.currentUserData?.user?.machine_id;
    },

    isAdmin() {
      return this.$store.state.authentication.isAdmin;
    },

    isShowNotification() {
      return (
        this.currentTab.includes("/atl") || this.currentTab.includes("/shl")
      );
    },
  },

  methods: {
    createNewUUID() {
      this.GUID = this.generateUUID();
    },

    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime();
      if (
        typeof performance !== "undefined" &&
        typeof performance.now === "function"
      ) {
        d += performance.now(); //use high-precision timer if available
      }
      var newGuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      return newGuid;
    },

    updateMachineGUID() {
      const payload = {
        guid: Cookies.get("deviceID"),
        machine_id: this.userMachineId,
      };

      Api()
        .put(`/machines/update-machine-guid/`, payload)
        .then((res) => {
          console.log(res);
        });
    },

    getMachineId() {
      this.aftMacId = localStorage.getItem("aftMacId");
    },

    // Pusher configuration
    /**
     * this function was not used
     */
    configurePusher() {
      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      });

      let that = this;
      let channelName = Cookies.get("deviceID") || "";

      let channels = [channelName, "heartbeat"].map((channelName) =>
        this.pusher.subscribe(channelName)
      );

      channels.forEach((channel) => {
        channel.bind("notifications", function (data) {
          that.$emit("incoming_data", data);
        });
      });

      this.$on("incoming_data", function (data) {
        this.pusherMessage = data;
        this.pusherData = data;
      });
    },

    // WebSocket Configuration
    connectWebSocket() {
      const socketUrl = process.env.VUE_APP_WEB_SOCKET_URL;
      const deviceId = Cookies.get("deviceID") || "";
      this.socket = new WebSocket(`${socketUrl}/${deviceId}/`);

      this.socket.addEventListener("open", (event) => {
        console.log("WebSocket connection established.", event?.type);
      });

      this.socket.addEventListener("message", (event) => {
        const parsedData = this.parseNestedJsonData(event.data);
        this.pusherMessage = parsedData;
        this.pusherData = parsedData;

        this.showErrorToast(
          parsedData?.message || "An error occurred. Try again later"
        );
      });

      this.socket.addEventListener("error", (event) => {
        console.error("WebSocket error:", event);
      });

      this.socket.addEventListener("close", (event) => {
        console.log("WebSocket connection closed:", event?.type);
      });
    },

    parseNestedJsonData(jsonString) {
      try {
        const data = JSON.parse(jsonString);
        return JSON.parse(data.message);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return null;
      }
    },

    //Show notifications
    showToast(data, type = "info") {
      this.$toast(data, {
        position: "top-right",
        timeout: 10000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        maxToasts: 7,
        icon: true,
        rtl: false,
        type: type,
      });
    },

    // Show error
    showErrorToast(data) {
      this.showToast(data, "error");
    },

    loginHandler(val) {
      let saveData = val;
      this.removeToken();
      let isManual = saveData.data.user?.machine_group === "Manual";
      setAuthToken(saveData.data.access);
      setRefreshToken(saveData.data.refresh);
      this.setCurrentUserInState(saveData);
      this.setIsLoggedIn(saveData.data.access);
      this.saveMachineStatusToLocal(saveData.data.machine_status);
      this.setIsAdmin(false);
      let userMachineId = saveData.data.user?.machine_id;
      this.setUserMachineId(userMachineId ? userMachineId : null);
      this.saveMachineIdToLocal(userMachineId);

      this.$router.push(
        `${isManual ? "/manual-worker" : ""}/atl/${this.userMachineId}`
      );
      location.reload();
    },

    saveMachineIdToLocal(machineId) {
      if (machineId) {
        localStorage.setItem("aftMacId", machineId);
      }
    },

    saveMachineStatusToLocal(machineStatus) {
      if (machineStatus) {
        localStorage.setItem("aftMacStatus", machineStatus);
      }
    },

    isNotServiceNotify(data) {
      return !data.service;
    },

    removeToken() {
      if (getAuthToken()) {
        clearAuthToken();
      }
    },

    getMachineIdFromRoute() {
      this.routeMachineId = this.$route.params.routeMachineId
        ? this.$route.params.routeMachineId
        : this.dynamicMachineId;
    },

    ...mapActions("authentication", [
      "setCurrentUserInState",
      "setIsLoggedIn",
      "setIsAdmin",
      "setUserMachineId",
    ]),

    ...mapActions("machines", ["setLabelStationData", "setDigitalStationData"]),
  },

  watch: {
    watch: {
      pusherMessage(val) {
        if (
          val?.message &&
          this.isShowNotification &&
          this.isNotServiceNotify(val)
        ) {
          if (this.lastToastMessage !== val.message) {
            this.lastToastMessage = val.message;
            this.showToast(val.message);
          }
        }
      },
    },
    data() {
      return {
        lastToastMessage: null,
      };
    },

    pusherData(val) {
      let isWorkerLogin =
        val?.data && val?.is_logout == "login" && !this.isAdmin;

      let isWorkerLogout =
        val?.is_logout == "logout" && val?.is_new_user == "no" && !this.isAdmin;

      let isServices = val?.service && this.isAdmin;

      if (isWorkerLogin) {
        this.loginHandler(val);
      }

      if (val?.is_logout === "error") {
        this.showErrorToast(val?.message);
        return;
      }

      if (isWorkerLogout) {
        this.$router.push(`/redirect/${this.routeMachineId}`);
        // location.reload(); //do not remove
      }

      if (isServices) {
        if (val?.service == "LabelStation") {
          this.setLabelStationData(val);
        }

        if (val?.service == "Shiftlog") {
          this.setDigitalStationData(val);
        }
      }
    },
    $route(to) {
      if (to.meta.requireWebSocket) {
        if (!this.socket) this.connectWebSocket();
      } else {
        if (this.socket) {
          this.socket.close();
          this.socket = null;
        }
      }
    },
  },

  created() {
    this.$watch(
      "$route.path",
      function () {
        this.currentTab = this.$router.history.current.fullPath;
      },
      { deep: true }
    );

    this.getMachineIdFromRoute();
    this.getMachineId();
    // this.configurePusher();
    this.connectWebSocket();

    if (!Cookies.get("deviceID") || "") {
      this.GUID = this.generateUUID();
      Cookies.set("deviceID", this.GUID);
    }
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
  font-family: Open Sans, sans-serif;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}
</style>
