import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/admin/HomeView.vue";
import MachineView from "../views/admin/MachineView.vue";
import UsersView from "../views/admin/UsersView.vue";
import LoginView from "../views/LoginView.vue";
import LoginAdminView from "../views/admin/LoginAdminView.vue";
import LoginWorkerView from "../views/LoginWorkerView.vue";
import LoginManualWorker from "../views/LoginManualWorker.vue";
import ATLView from "../views/ATLView.vue";
import SHLView from "../views/SHLView.vue";
import AdminEditProfileView from "../views/admin/EditProfileView.vue";
import AdminChangePasswordView from "../views/admin/PasswordView.vue";
import MachineGroup from "../views/admin/MachineGroup.vue";
import UserRole from "../views/admin/UserRole.vue";
import ManualMessages from "../views/admin/ManualMessages.vue";
import AutomaticMessages from "../views/admin/AutomaticMessages.vue";
import NotFoundView from "../views/NotFoundView.vue";
import store from "../store/index";
import AdminATLView from "../views/admin/AdminATLView.vue";
import AdminSHLView from "../views/admin/AdminSHLView.vue";
import AdminManualATLView from "../views/admin/AdminManualATLView.vue";
import AdminManualSHLView from "../views/admin/AdminManualSHLView.vue";
import AllShiftsView from "../views/admin/AllShiftsView.vue";
import RedirectView from "../views/RedirectView.vue";
import AssignedWorkerView from "../views/admin/AssignedWorkerView.vue";
import UpdateMachineGUID from "../views/admin/UpdateMachineGUID.vue";
import ManualWorkerATLView from "../views/ManualWorkerATLView.vue";
import ManualWorkerSHLView from "../views/ManualWorkerSHLView.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      // requiresAuth: true,
      // pageType: "ADMIN",
    },
  },
  {
    path: "/machine",
    name: "machine",
    component: MachineView,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      requireWebSocket: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      requireWebSocket: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/login",
    name: "LoginView",
    meta: {
      layout: "blank",
      allowAnonymous: true,
    },
    component: LoginView,
  },
  {
    path: "/login-admin",
    name: "loginAdmin",
    meta: {
      layout: "blank",
      allowAnonymous: true,
      requireWebSocket: true,
    },
    component: LoginAdminView,
  },
  {
    path: "/login-worker",
    name: "loginWorker",
    meta: {
      layout: "blank",
      allowAnonymous: true,
      requireWebSocket: true,
    },
    component: LoginWorkerView,
  },
  {
    path: "/login-manual-worker",
    name: "loginManualWorker",
    meta: {
      layout: "blank",
      allowAnonymous: true,
      requireWebSocket: true,
    },
    component: LoginManualWorker,
  },
  {
    path: "/atl/:routeMachineId",
    name: "atl",
    component: ATLView,
    meta: {
      layout: "navbar",
      allowAnonymous: true,
      requireWebSocket: true,
    },
  },
  {
    path: "/manual-worker/atl/:routeMachineId",
    name: "manualWorkerAtl",
    component: ManualWorkerATLView,
    meta: {
      layout: "navbar",
      allowAnonymous: true,
      requireWebSocket: true,
    },
  },
  {
    path: "/admin/edit-profile",
    name: "adminEditProfile",
    component: AdminEditProfileView,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/admin/change-password",
    name: "adminChangePassword",
    component: AdminChangePasswordView,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/machine-group",
    name: "machineGroup",
    component: MachineGroup,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/user-role",
    name: "userRole",
    component: UserRole,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      requireWebSocket: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/manual-messages",
    name: "manualMessages",
    component: ManualMessages,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      requireWebSocket: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/automatic-messages",
    name: "automaticMessages",
    component: AutomaticMessages,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      requireWebSocket: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/shl/:routeMachineId",
    name: "shl",
    component: SHLView,
    meta: {
      layout: "navbar",
      allowAnonymous: true,
      requireWebSocket: true,
    },
  },
  {
    path: "/manual-worker/shl/:routeMachineId",
    name: "manualMachinesShl",
    component: ManualWorkerSHLView,
    meta: {
      layout: "navbar",
      allowAnonymous: true,
      requireWebSocket: true,
    },
  },
  {
    path: "/admin-atl/:routeMachineId",
    name: "adminAtl",
    component: AdminATLView,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      requireWebSocket: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/admin-shl/:routeMachineId",
    name: "adminShl",
    component: AdminSHLView,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      requireWebSocket: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/admin-manual-atl/:routeMachineId",
    name: "adminManualAtl",
    component: AdminManualATLView,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      requireWebSocket: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/admin-manual-shl/:routeMachineId",
    name: "adminManualShl",
    component: AdminManualSHLView,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      requireWebSocket: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/all-shifts/:routeMachineId",
    name: "allShifts",
    component: AllShiftsView,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/admin/assigned-worker/:rfidKey",
    name: "assignedWorker",
    component: AssignedWorkerView,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      requireWebSocket: true,
      pageType: "ADMIN",
    },
  },

  {
    path: "/redirect/:routeMachineId",
    name: "redirect",
    component: RedirectView,
    meta: {
      layout: "blank",
      allowAnonymous: true,
    },
  },
  {
    path: "/machine/update-guid/:machineID",
    name: "updateGuid",
    component: UpdateMachineGUID,
    meta: {
      layout: "navbar",
      allowAnonymous: false,
      requiresAuth: true,
      pageType: "ADMIN",
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },

  {
    path: "*",
    name: "notFound",
    component: NotFoundView,
    meta: {
      layout: "blank",
      allowAnonymous: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _from, next) => {
  const isAdmin = store.state.authentication.isAdmin;

  let redirectObject = {
    name: "loginAdmin",
    query: { redirect: to.fullPath },
  };

  if (to.meta.requiresAuth && to.meta.pageType === "ADMIN" && !isAdmin) {
    next(redirectObject);
  } else {
    next();
  }
});

export default router;
